import { NotificationRowProps, NotificationRowType } from './props';
import { memo, useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useCountUp } from 'use-count-up';
import { useDispatch } from 'react-redux';
import NotificationRowContent from './Content';
import styles from './styles.module.scss';
import { removeNotificationToasty } from '@/lib/store/notifications/actions';
import { NOTIFICATION_DURATION } from '@/services/NotificationsService';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';
import { useComponentVariant } from '@/hooks/theme/useComponentVariant';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { CircularProgress } from '@starsoft/common/components';
import { AlertExtraParams } from '@starsoft/common/models';

function NotificationRow({ notification, type }: NotificationRowProps) {
  const startTime = useMemo(
    () => notification && new Date(notification?.createdAt).getTime(),
    [notification],
  );
  const endTime = useMemo(
    () =>
      notification &&
      new Date(notification?.createdAt).getTime() - NOTIFICATION_DURATION,
    [notification],
  );

  const dispatch = useDispatch();
  const notificationsVersion: SettingLayoutComponentId = useComponentVariant(
    ComponentTypeId.Notifications,
  );
  const toastyVersion: SettingLayoutComponentId = useComponentVariant(
    ComponentTypeId.Toasty,
  );
  const typeId: NotificationTypeId =
    notification?.type?.id ?? notification?.typeId;

  function handleRemoveNotification(): void {
    dispatch(removeNotificationToasty(notification?.id));
  }

  const { value } = useCountUp({
    start: startTime,
    end: endTime,
    duration: 10,
    updateInterval: 0.1,
    easing: 'linear',
    isCounting: type === NotificationRowType.Toasty,
    onComplete: handleRemoveNotification,
  });

  const progress: number = useMemo(
    () => 100 * ((endTime - (value as number)) / (endTime - startTime)),
    [endTime, startTime, value],
  );

  return (
    <div
      className={`${styles.container} ${type === NotificationRowType.Row ? styles['container--row'] : styles['container--toasty']} ${notificationsVersion === SettingLayoutComponentId.NotificationsV2 && type === NotificationRowType.Row ? styles['container--row--v2'] : ''} ${toastyVersion === SettingLayoutComponentId.ToastyV3 ? styles[`container--${typeId === NotificationTypeId.Alert ? (notification.extra as AlertExtraParams).type : 'v3'}`] : ''}`}
    >
      <NotificationRowContent notification={notification} type={type} />

      {type == NotificationRowType.Toasty && (
        <>
          {toastyVersion === SettingLayoutComponentId.ToastyV3 && (
            <div
              className={`${styles['container__progress-bar']} ${typeId === NotificationTypeId.Alert ? styles[`container__progress-bar--${(notification.extra as AlertExtraParams).type}`] : ''}`}
              style={{
                width: `${progress}%`,
              }}
            />
          )}
          <div className={styles['container__close-button']}>
            <i
              onClick={handleRemoveNotification}
              className={`${styles['container__close-button__icon']} fa-solid fa-xmark`}
            />
            {toastyVersion !== SettingLayoutComponentId.ToastyV3 && (
              <div className={styles['container__close-button__progress']}>
                <CircularProgress progress={progress} invert />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default memo(NotificationRow);
