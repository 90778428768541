import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { WithdrawalNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { TransactionExtraParams } from '@/models/notification/notificationExtraParams';
import NotificationCoin from '../../Coin';
import { WalletService } from '@starsoft/common/services';
import { CoinType } from '@starsoft/common/models';

export default function WithdrawContent({
  notification,
  type,
}: NotificationContentProps<WithdrawalNotification>) {
  const { t } = useTranslation('notify');
  const extra: TransactionExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-sack-dollar"
      title={t('withdraw_title')}
      description={t('withdraw_approved_description', {
        amount: WalletService.maskCurrency({
          amount: extra.amount,
          coin: extra.coin,
          hideSymbol: extra.coin.type === CoinType.Fiat,
        }),
      })}
      components={{
        coin: <NotificationCoin coin={extra.coin} />,
      }}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
