import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { NotificationContentProps } from '../props';
import { KYCStatus } from '@/enums/kyc/kycStatus';
import { KYCError } from '@/enums/kyc/kycError';
import { KycExtraParams, KYCNotification } from '@starsoft/common/models';

export default function KycContent({
  notification,
  type,
}: NotificationContentProps<KYCNotification>) {
  const { t } = useTranslation('notify');
  const extra: KycExtraParams = notification.extra;

  //TODO - modificar notificação KYC

  function getIcon() {
    switch (extra.status) {
      case KYCStatus.Verified:
        return 'fa-duotone fa-file-check';
      case KYCStatus.Created:
        return 'fa-solid fa-file-excel';
      default:
        return 'fa-duotone fa-file-check';
    }
  }

  function getTitle() {
    switch (extra.status) {
      case KYCStatus.Verified:
        return t('kyc_accepted_title');
      case KYCStatus.Created:
        return t('kyc_rejected_title');
      default:
        return t('kyc_accepted_title');
    }
  }

  function getKycRejectedDescription(): string {
    switch (extra.error) {
      case KYCError.BackDocumentUnreadable:
        return 'kyc_rejected_backdocument';
      case KYCError.FrontDocumentUnreadable:
        return 'kyc_rejected_frontdocument';
      case KYCError.IdentityMismatch:
        return 'kyc_rejected_unmatched';
      case KYCError.ResidencyProofUnreadable:
        return 'kyc_rejected_adress';
      case KYCError.SelfieUnreadable:
        return 'kyc_rejected_selfie_document';
      default:
        return 'kyc_rejected_adress';
    }
  }

  function getDescription() {
    switch (extra.status) {
      case KYCStatus.Created:
        return t('kyc_accepted_description');
      case KYCStatus.Verified:
        return t(getKycRejectedDescription());
      default:
        return t('kyc_accepted_description');
    }
  }

  return (
    <BaseContent
      icon={getIcon()}
      title={getTitle()}
      description={getDescription()}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
