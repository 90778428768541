import styles from './styles.module.scss';
import { NotificationCoinProps } from './props';
import { Image } from '@starsoft/common/components';

export default function NotificationCoin({ coin }: NotificationCoinProps) {
  return (
    <Image
      className={styles.coin}
      width={16}
      height={16}
      alt={`coin-icon-${coin?.name}`}
      format="square"
      src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${coin?.image}`}
    />
  );
}
