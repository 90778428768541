import useTranslation from 'next-translate/useTranslation';
import { NotificationContentProps } from '../props';
import { CouponExtraParams } from '@/models/notification/notificationExtraParams';
import { useLottiePlayer } from '@starsoft/common/hooks';
import styles from './styles.module.scss';
import { CouponNotification } from '@/models/notification/notification';

export default function CouponContent({
  notification,
}: NotificationContentProps<CouponNotification>) {
  const { t } = useTranslation('notify');
  const extra: CouponExtraParams = notification.extra;
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/gift-box-deposit.json',
    dependecyArray: [],
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__column}>
        <span className={styles.container__title}>
          {t('deposit_bonus_title')}
        </span>
        <p className={styles.container__description}>
          {t('deposit_bonus_description', {
            percentage: extra.percentage,
          })}
        </p>
      </div>
      <div className={styles.container__lottie} ref={lottieAnimationRef} />{' '}
    </div>
  );
}
