export enum Language {
  Chinese = 'zh',
  Russian = 'ru',
  Portuguese = 'pt',
  French = 'fr',
  Spanish = 'es',
  English = 'en',
  Dutch = 'de',
  Indonesian = 'id',
  Italian = 'it',
}
