import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { GameID } from '@/enums/gameId';
import { Bet } from '@/models/bet';
import { getRouletteMultiplier } from '@/components/core/Games/GameLeaderboard/Content/Table/Body/Row/Multiplier/helper';
import { RouletteColor } from '@/models/roulette/color';
import { NotificationContentProps } from '../props';
import { GameBetNotification } from '@/models/notification/notification';
import { WalletService } from '@starsoft/common/services';

export default function BetContent({
  notification,
  type,
}: NotificationContentProps<GameBetNotification>) {
  const { t } = useTranslation('common');
  const bet: Bet = notification.extra;

  function getDescription(): string {
    switch (bet?.game?.id) {
      case GameID.Slide:
      case GameID.Double:
        return 'notification_bet_multiplier_description';
      default:
        return 'notification_bet_description';
    }
  }

  function getMultiplier(): string {
    switch (bet?.game?.id as GameID) {
      case GameID.Crash:
      case GameID.CrashTrenball:
      case GameID.Slide:
        return `${Number(bet?.crashpointBet?.crashpoint)?.toFixed(2)}x`;
      case GameID.Double:
        return `${Number(getRouletteMultiplier(bet?.rouletteBet?.color as RouletteColor))?.toFixed(2)}x`;
    }
  }

  return (
    <BaseContent
      icon={bet?.game?.icon as string}
      title={t('notification_bet_title', {
        game: bet?.game?.name,
      })}
      description={t(getDescription(), {
        amount: WalletService.maskCurrency({
          amount: bet?.amount,
          coin: bet?.coin,
        }),
        game: bet?.game?.name,
        multiplier: getMultiplier(),
      })}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
