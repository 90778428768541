import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { NotificationContentProps } from '../props';
import {
  AlertExtraParams,
  AlertNotification,
  AlertType,
} from '@starsoft/common/models';

export default function AlertContent({
  notification,
  type,
}: NotificationContentProps<AlertNotification>) {
  const { t } = useTranslation('notify');
  const extra: AlertExtraParams = notification.extra;

  function getAlertIcon(type: AlertType) {
    switch (type) {
      case AlertType.Info:
        return 'fa-solid fa-info-square';
      case AlertType.Success:
        return 'fa-solid fa-check-circle';
      case AlertType.Error:
        return 'fa-solid fa-exclamation-triangle';
    }
  }

  return (
    <BaseContent
      icon={getAlertIcon(extra?.type as AlertType)}
      title={t(extra?.title as string)}
      description={t(extra?.message as string, extra?.params ?? {}) as string}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
