import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { TournamentNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { TournamentExtraParams } from '@/models/notification/notificationExtraParams';
import { WalletService } from '@starsoft/common/services';
import NotificationCoin from '../../Coin';
import { useLanguageDictionary } from '@/hooks/useLanguageDictionary';
import { I18n } from '@/services/I18n';
import { CoinType } from '@starsoft/common/models';

export default function TournamentContent({
  notification,
  type,
}: NotificationContentProps<TournamentNotification>) {
  const { t } = useTranslation('notify');
  const extra: TournamentExtraParams = notification.extra;

  const nameI18n: I18n = useLanguageDictionary(extra?.tournament?.name);

  return (
    <BaseContent
      icon="fa-solid fa-trophy-star"
      title={t('tournament_title', {
        name: nameI18n.t(),
      })}
      description={t('tournament_description', {
        place: extra.place,
        prize: WalletService.maskCurrency({
          coin: extra.coin,
          amount: extra.prize,
          hideSymbol: extra.coin.type === CoinType.Fiat,
        }),
      })}
      components={{
        coin: <NotificationCoin coin={extra.coin} />,
      }}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
