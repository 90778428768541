import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { FollowMode } from '@/services/dto/follow/follow-mode';
import { NotificationContentProps } from '../props';
import { FollowNotification } from '@/models/notification/notification';
import { FollowExtraParams } from '@/models/notification/notificationExtraParams';

export default function FollowContent({
  notification,
  type,
}: NotificationContentProps<FollowNotification>) {
  const { t } = useTranslation('common');
  const extra: FollowExtraParams = notification.extra;

  function getDescription(mode: FollowMode) {
    switch (mode) {
      case FollowMode.All:
        return 'notification_follower_all_description';
      case FollowMode.Casino:
        return 'notification_follower_casino_description';
      case FollowMode.Sports:
        return 'notification_follower_sports_description';
      default:
        return 'notification_follower_all_description';
    }
  }

  return (
    <BaseContent
      icon="fa-solid fa-user-tie"
      title={t('notification_follower_title')}
      description={t(getDescription(extra.mode), {
        follower: extra.username,
        margin: extra.margin,
      })}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
