import { useTimeAgo } from '@starsoft/common/hooks';
import { BaseContentProps } from './props';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import dynamic from 'next/dynamic';
import { ComponentTypeId } from '@starsoft/common/models';
import { useComponentVariant } from '@/hooks/theme/useComponentVariant';

const BaseContentV1 = dynamic(() => import('./V1'), { ssr: true });
const BaseContentV2 = dynamic(() => import('./V2'), { ssr: true });
const BaseContentV3 = dynamic(() => import('./V3'), { ssr: true });

export function BaseContent({ createdAt, ...props }: BaseContentProps) {
  const formattedDate = useTimeAgo({
    date: new Date(createdAt),
  });
  const version: SettingLayoutComponentId = useComponentVariant(
    ComponentTypeId.Toasty,
  );

  switch (version) {
    case SettingLayoutComponentId.ToastyV1:
      return <BaseContentV1 createdAt={formattedDate} {...props} />;
    case SettingLayoutComponentId.ToastyV2:
      return <BaseContentV2 createdAt={formattedDate} {...props} />;
    case SettingLayoutComponentId.ToastyV3:
      return <BaseContentV3 createdAt={formattedDate} {...props} />;
    default:
      return <BaseContentV1 createdAt={formattedDate} {...props} />;
  }
}
