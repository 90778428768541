import { Language } from '@/enums/language';
import { LanguageDictionary } from '@/models/languageDictionary';
import { I18n } from '@/services/I18n';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

export function useLanguageDictionary(dictionary: LanguageDictionary) {
  const { lang } = useTranslation();
  const language: Language = useMemo(
    () => (lang?.substring(0, 2) as Language) ?? Language.English,
    [lang],
  );
  const i18n: I18n = useMemo(
    () => new I18n(dictionary, language as Language),
    [language, dictionary],
  );

  return i18n;
}
