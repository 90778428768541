import { LanguageDictionary } from '@/models/languageDictionary';
import { I18nParams } from './props';
import { Language } from '@/enums/language';

export class I18n {
  public readonly dictionary: LanguageDictionary;
  public readonly language: Language;

  constructor(dictionary: LanguageDictionary, language: Language) {
    this.dictionary = dictionary;
    this.language = language;
  }

  public t(params?: I18nParams) {
    let value: string = this.dictionary?.[this.language];

    if (params) {
      for (const param in params) {
        const regexParam = new RegExp(`{{${param}}}`, 'g');
        value = value.replace(regexParam, String(params[param]));
      }
    }

    return value;
  }
}
