import DepositContent from './BaseContent/Deposit';
import WithdrawContent from './BaseContent/Withdraw';
import BetContent from './BaseContent/Bet';
import AlertContent from './BaseContent/Alert';
import FollowContent from './BaseContent/Follow';
import KycContent from './BaseContent/Kyc';
import BonusWithdrawalContent from './BaseContent/BonusWithdrawal';
import CouponContent from './BaseContent/Coupon';
import BonusContent from './BaseContent/Bonus';
import TournamentContent from './BaseContent/Tournament';
import { NotificationRowProps } from '../props';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { isNotificationOfType } from '@/utils/notifications/isNotificationOfType';
import {
  BonusWithdrawalNotification,
  ComissionNotification,
  CouponNotification,
  FollowNotification,
  GameBetNotification,
  TournamentNotification,
} from '@/models/notification/notification';
import {
  AlertNotification,
  DepositNotification,
  KYCNotification,
  WithdrawalNotification,
} from '@starsoft/common/models';

export default function NotificationRowContent({
  notification,
  type,
}: NotificationRowProps) {
  const typeId = notification?.type?.id ?? notification?.typeId;

  switch (typeId) {
    case NotificationTypeId.Coupon:
      if (
        !isNotificationOfType<CouponNotification>(
          notification,
          NotificationTypeId.Coupon,
        )
      ) {
        return null;
      }

      return <CouponContent notification={notification} type={type} />;
    case NotificationTypeId.Deposit:
      if (
        !isNotificationOfType<DepositNotification>(
          notification,
          NotificationTypeId.Deposit,
        )
      ) {
        return null;
      }

      return <DepositContent notification={notification} type={type} />;
    case NotificationTypeId.Withdrawal:
      if (
        !isNotificationOfType<WithdrawalNotification>(
          notification,
          NotificationTypeId.Withdrawal,
        )
      ) {
        return null;
      }

      return (
        <WithdrawContent
          notification={notification as WithdrawalNotification}
          type={type}
        />
      );
    case NotificationTypeId.KYC:
      if (
        !isNotificationOfType<KYCNotification>(
          notification,
          NotificationTypeId.KYC,
        )
      ) {
        return null;
      }

      return <KycContent notification={notification} type={type} />;

    case NotificationTypeId.Comission:
      if (
        !isNotificationOfType<ComissionNotification>(
          notification,
          NotificationTypeId.Comission,
        )
      ) {
        return null;
      }

      return <BonusContent notification={notification} type={type} />;
    case NotificationTypeId.BonusWithdrawal:
      if (
        !isNotificationOfType<BonusWithdrawalNotification>(
          notification,
          NotificationTypeId.BonusWithdrawal,
        )
      ) {
        return null;
      }

      return <BonusWithdrawalContent notification={notification} type={type} />;
    case NotificationTypeId.Follow:
      if (
        !isNotificationOfType<FollowNotification>(
          notification,
          NotificationTypeId.Follow,
        )
      ) {
        return null;
      }

      return <FollowContent notification={notification} type={type} />;
    /* case NotificationTypeId.Bet:
      if (
        !isNotificationOfType<BetNotification>(
          notification,
          NotificationTypeId.Bet,
        )
      ) {
        break;
      }

      return <CopyContent notification={notification} type={type} />; */
    case NotificationTypeId.GameBet:
      if (
        !isNotificationOfType<GameBetNotification>(
          notification,
          NotificationTypeId.GameBet,
        )
      ) {
        return null;
      }

      return <BetContent notification={notification} type={type} />;
    case NotificationTypeId.Alert:
      if (
        !isNotificationOfType<AlertNotification>(
          notification,
          NotificationTypeId.Alert,
        )
      ) {
        return null;
      }

      return <AlertContent notification={notification} type={type} />;
    case NotificationTypeId.Tournament:
      if (
        !isNotificationOfType<TournamentNotification>(
          notification,
          NotificationTypeId.Tournament,
        )
      ) {
        return null;
      }

      return <TournamentContent notification={notification} type={type} />;
    default:
      null;
  }
}
